import React, { FC, useState } from 'react';
import { BazaarVoiceInlineRating, BazaarVoiceReviews } from '@phx-husky/bazaarvoice-components';

import Button, { VisualType } from 'common/Button';

import { BazaarVoiceDropdownProps } from './models';

import './BazaarVoiceDropdown.scss';

const BazaarVoiceDropdown: FC<BazaarVoiceDropdownProps> = ({
  bvProductId,
  showBazaarvoiceStarRating,
  bazaarvoiceSeeReviewLabel,
  visualVariant,
  closeButtonAriaLabel,
}) => {
  const [isDropVisible, setIsDropVisible] = useState<boolean>(false);
  const handleOpenDrop = () => {
    setIsDropVisible(!isDropVisible);
  };

  return showBazaarvoiceStarRating && bvProductId ? (
    <div className="bazaar-voice-dropdown" data-testid="bazaar-voice-dropdown">
      <BazaarVoiceInlineRating productId={bvProductId} />

      {bazaarvoiceSeeReviewLabel ? (
        <div className="bazaar-voice-dropdown__wrapper">
          <Button
            type="button"
            ariaLabel={bazaarvoiceSeeReviewLabel}
            visualType={VisualType.Opener}
            visualVariant={visualVariant}
            onClick={handleOpenDrop}
          >
            {bazaarvoiceSeeReviewLabel}
          </Button>

          {isDropVisible ? (
            <div className="bazaar-voice-dropdown__drop">
              <BazaarVoiceReviews productId={bvProductId} />
              {closeButtonAriaLabel ? (
                <button
                  type="button"
                  className="bazaar-voice-dropdown__drop-closer"
                  onClick={handleOpenDrop}
                >
                  {closeButtonAriaLabel}
                </button>
              ) : null}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  ) : null;
};

export default BazaarVoiceDropdown;
