import React, { FC } from 'react';
import { useId } from 'react-id-generator';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import Banner from 'components/Banner';
import { BannerMode } from 'components/Banner/models.d';
import PageDescription from 'components/PageDescription';
import { bodyAdapter } from 'utils/bodyAdapter';
import BodyRenderer from 'utils/bodyRenderer';
import createBreadcrumbs from 'utils/createBreadcrumbs';

import { ArticleCategoryPageProps } from './models';

const ArticleCategoryPage: FC<{
  data: ArticleCategoryPageProps;
  pageContext: PageContent.PageContext;
  location: PageContent.LocationType;
}> = ({
  data: {
    articleCategoryPage: {
      langProps,
      seo,
      pageDescription,
      banner: [banner],
      body,
    },
    allSitePage: { nodes },
  },
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
    pagePathname,
  },
  location: { pathname },
}) => {
  const breadcrumbs = createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname);

  return (
    <Layout {...{ seo, breadcrumbs, langProps, pagePathname }}>
      {banner ? <Banner {...banner} mode={BannerMode.article} /> : null}
      {pageDescription ? <PageDescription {...pageDescription} /> : null}
      {bodyAdapter(body).map((component) => {
        const [key] = useId();

        return <BodyRenderer {...component} key={key} />;
      })}
    </Layout>
  );
};

export const query = graphql`
  query($link: String) {
    articleCategoryPage(link: { eq: $link }) {
      langProps {
        urls {
          ...languageUrls
        }
        lang
      }
      seo {
        ...SEOStructureFragment
      }
      pageDescription {
        description
        backgroundColor
      }
      banner {
        ...BannerFragment
      }
      body {
        textWithImage {
          ...TextWithImageFragment
        }
        teaser {
          ...TeaserFragment
        }
        productsListing {
          ...ListingFragment
        }
        articlePromoListing {
          ...ListingFragment
        }
        newsletterNodePicker {
          ...NewsletterBannerFragment
        }
      }
    }
    allSitePage {
      ...FragmentAllPageSite
    }
  }
`;

export default ArticleCategoryPage;
